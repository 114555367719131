












































import { ChannelInfo } from "@/includes/types/Board.types"
import { successNotification } from '@/includes/services/NotificationService'

import ServicesCart from 'piramis-base-components/src/plugins/AdminTemplate/pages/services-cart.vue'
import AListCustom from 'piramis-base-components/src/components/AListCustom/AListCustom.vue'
import AListCustomItem from "piramis-base-components/src/components/AListCustom/AListCustomItem.vue"
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import { PayInfos } from 'piramis-base-components/src/components/ServicesCart/logic/types'
import PageTitle from "piramis-base-components/src/components/PageTitle.vue"

import { Component, Mixins } from 'vue-property-decorator'
import moment from "moment"

@Component({
  data() {
    return {
      moment,
    }
  },
  components: {
    ServicesCart,
    PageTitle,
    AListCustom,
    AListCustomItem
  }
})
export default class BuyChannel extends Mixins<UseFields>(UseFields) {
  payMethods = this.defaultPayMethods()

  payInfo: PayInfos | null = null

  updatePayInfo(data: PayInfos) {
    this.payInfo = data
  }

  get channel(): ChannelInfo {
    return this.$store.state.channelsState.activeChannel
  }

  setChannelIds(args: FieldData) {
    args.setter = (value: Array<number>) => {
      if (!value.length) {
        this.$set(args.model, args.key, [ this.channel.id ])
      } else {
        if (value.includes(this.channel.id)) {
          this.$set(args.model, args.key, value)
        }
      }
    }

    return args
  }

  onPromocodeActivate(data:any) {
    if (data?.promocode_success) {
      successNotification(this.$t('promocode_success_activated_text').toString())
    }
  }

  defaultPayMethods() {
    return {
      targetIdKey: 'channel_ids',
      config: {
        target_id: {
          board_key: this.$store.getters.activeBoard.board,
          channel_ids: [ this.$store.state.channelsState.activeChannel.id ]
        },
        target_type: 'channel'
      }
    }
  }
}
