var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"buy-channel"},[_c('page-title'),_c('select-input',{staticClass:"channel-ids-select",attrs:{"setup":{
      'func': _vm.setChannelIds,
      'args': {
        'model': _vm.payMethods.config.target_id,
        'key': _vm.payMethods.targetIdKey,
        'prefix': 'payment_',
        'options': _vm.$store.getters.boardTargetOptions,
        'multiple': true,
        'selectAll': true
      },
    }}}),(_vm.payMethods && _vm.payMethods.config.target_id.channel_ids.length > 1)?_c('a-alert',{staticClass:"flex items-center",scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('a-icon',{staticClass:"text-lg text-danger",attrs:{"type":"shopping-cart"}}),_c('span',{staticClass:"text-lg",domProps:{"innerHTML":_vm._s(_vm.$t('total_message', { sale: _vm.payInfo.sale, len: _vm.payMethods.config.target_id.channel_ids.length, totalPrice: _vm.payInfo.tariffs[0].total, symbol: _vm.payInfo.tariffs[0].currency_symbol }))}})]},proxy:true}],null,false,2042176468)}):_vm._e(),_c('services-cart',{staticClass:"mt-5",on:{"update-pay-info":_vm.updatePayInfo,"on-promocode-activate":_vm.onPromocodeActivate},model:{value:(_vm.payMethods),callback:function ($$v) {_vm.payMethods=$$v},expression:"payMethods"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }